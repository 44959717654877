import React from 'react'
import PhysicianLandingPage from 'containers/physician-landing-page'

const UMNLandingPage = () => (
  <PhysicianLandingPage
    physician="Joshua Bodie, MD"
    institution="University of Minnesota"
    referralCode="UMN"
    physicianURL="https://med.umn.edu/bio/urology-faculty/joshua-bodie"
  />
)

export default UMNLandingPage
